<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="战队名称：">
        <el-input size="small" v-model="team_name" placeholder="请输入战队名称"></el-input>
      </el-form-item>
      <el-form-item label="队员名称：">
        <el-input size="small" v-model="user_name" placeholder="请输入队员名称"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input size="small" v-model="mobile" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="searchFn">搜索</el-button>
        <el-button size="small" v-if="tabsActive === 'first'" @click="exportExel">导出</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 选择是全部战队还是决赛排名 -->
    <el-tabs v-model="tabsActive" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部比赛" name="first"></el-tab-pane>
      <el-tab-pane label="决赛排名" name="second"></el-tab-pane>
    </el-tabs>

    <!-- 展示表格 -->
    <el-table :data="dataList" style="width: 100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column type="expand">
        <template slot-scope="props">
          <!-- 展示子级 -->
          <el-table :data="props.row.team_user_list" style="width: 100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column label="报名人身份" prop="team_name" align="center">
              <template v-slot="{ row }">
                {{ row.is_team_leader ? '队长' : '队员' }}
              </template>
            </el-table-column>
            <el-table-column label="报名人姓名" prop="user_name" align="center"></el-table-column>
            <el-table-column label="联系电话" prop="mobile" align="center"></el-table-column>
            <el-table-column label="QQ号码" prop="qq" align="center"></el-table-column>
            <el-table-column label="段位" prop="dan" align="center"></el-table-column>
            <el-table-column label="擅长位置" prop="preferred_role" align="center"></el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="战队名称" prop="team_name" align="center"></el-table-column>
      <el-table-column label="战队logo" align="center">
        <template v-slot="{ row }">
          <el-image :style="{ width: '50px', height: '50px' }" :preview-src-list="[row.logo]" :src="row.logo"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="报名人姓名" prop="user_name" align="center"></el-table-column>
      <el-table-column label="联系电话" prop="mobile" align="center"></el-table-column>
      <el-table-column label="战队队员数" prop="team_user_count" align="center"></el-table-column>
      <el-table-column v-if="tabsActive === 'first'" label="是否参与决赛" align="center">
        <template v-slot="{ row }">
          <!-- :disabled="row.is_final" -->
          <el-switch v-model="row.is_final" :active-value="1" :inactive-value="0" @change="setFinal(row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column v-else label="设置排名" align="center">
        <template v-slot="{ row }">
          <el-input :disabled="row.isRanking" style="width: 100px" size="mini" type="number" v-model="row.ranking" :min="1"></el-input>
          <el-button v-if="!row.isRanking" style="margin-left: 10px" size="small" type="text" @click="sureRank(row)">确认</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging v-if="tabsActive === 'first'" :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import config from '@/util/config';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      tabsActive: 'first',
      page: 1,
      rows: 10,
      total: 0,
      searchForm: {
        activity_id: 0,
        team_name: '',
        user_name: '',
        mobile: '',
      },
      dataList: [],
      updateRank: '',
    };
  },
  created() {
    if (this.$route.query.activity_id) {
      this.searchForm.activity_id = Number(this.$route.query.activity_id);
    } else {
      this.$router.push('/extension/activity/activityList');
    }
    this.getDataList(this.$api.sports.lockTeamList);
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getDataList(this.$api.sports.lockTeamList);
    },
    searchClear() {
      this.page = 1;
      this.searchForm.team_name = '';
      this.searchForm.user_name = '';
      this.searchForm.mobile = '';
      let path = this.tabsActive === 'first' ? this.$api.sports.lockTeamList : this.$api.sports.teamRankList;
      this.getDataList(path);
    },
    // 切换列表
    handleClick() {
      this.searchClear();
    },
    // 点击进行搜索
    searchFn() {
      this.page = 1;
      let path = this.tabsActive === 'first' ? this.$api.sports.lockTeamList : this.$api.sports.teamRankList;
      this.getDataList(path);
    },
    getDataList(url) {
      let obj = {
        activity_id: this.searchForm.activity_id,
      };
      if (this.tabsActive === 'first') {
        obj.page = this.page;
        obj.rows = this.rows;
      }
      if (this.searchForm.team_name) obj.team_name = this.searchForm.team_name;
      if (this.searchForm.user_name) obj.user_name = this.searchForm.user_name;
      if (this.searchForm.mobile) obj.mobile = this.searchForm.mobile;
      this.$axios.post(url, obj).then(res => {
        if (res.code === 0) {
          if (this.tabsActive === 'first') {
            this.dataList = res.result.list;
            this.total = res.result.total_number;
          } else {
            this.dataList = res.result;
            this.dataList.map(item => {
              if (item.ranking) {
                this.$set(item, 'isRanking', 1);
              } else {
                this.$set(item, 'isRanking', 0);
              }
            });
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 设置队伍排名
    sureRank(data) {
      if (!data.ranking || data.ranking <= 0) {
        this.$message.error('排名不能小于0');
        return;
      }
      this.$axios.post(this.$api.sports.setRanking, { team_id: data.id, ranking: data.ranking }).then(res => {
        if (res.code === 0) {
          this.$set(data, 'isRanking', 1);
          this.$message.success('设置成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 设置队伍是否参加决赛
    setFinal(data) {
      this.$axios
        .post(this.$api.sports.joinFinal, {
          team_id: data.id,
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('设置成功');
          } else {
            this.$message.error(res.msg);
            if (data.is_final) {
              data.is_final = 0;
            } else {
              data.is_final = 1;
            }
          }
        });
    },
    // 导出
    exportExel() {
      this.$axios
        .post(this.$api.sports.teamUserExport, {
          activity_id: this.searchForm.activity_id,
        })
        .then(res => {
          if (res.code == 0) {
            let path = config.baseurl + '/' + res.result;
            let a = document.createElement('a');
            a.href = path;
            a.id = 'download';
            document.body.appendChild(a);
            a.click();
            let aDom = document.getElementById('download');
            document.body.removeChild(aDom);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .el-table {
    .el-input {
      /deep/ input {
        text-align: center;
      }
    }
  }
}
</style>
